import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import ResponsiveSidebar from "../components/ResponsiveSidebar/ResponsiveSidebar"
import SidenavNavigation from "../components/SidenavNavigation/SidenavNavigation"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt
      html
      frontmatter {
        title
      }
    }
  }
`

const AboutUsTemplate = ({ data: { markdownRemark: aboutUs } }) => {
  const sidenavNavigationListItems = [
    {
      title: "Historia",
      url: "/about-us/historia",
    },
    {
      title: "Instalaciones, trabajos y obras realizadas",
      url: "/about-us/instalaciones",
      subItems: [
        {
          title: "Exacaman",
          url: "/about-us/exacaman",
        },
        {
          title: "Minas de Almaden",
          url: "/about-us/minas-almaden",
        },
        {
          title: "Agrocaja",
          url: "/about-us/agrocaja",
        },
        {
          title: "Emaser",
          url: "/about-us/emaser",
        },
        {
          title: "Tragsa",
          url: "/about-us/tragsa",
        },
        {
          title: "Otras obras",
          url: "/about-us/otras-obras",
        },
      ],
    },
    {
      title: "Calidad",
      url: "/about-us/calidad",
    },
    {
      title: "Recursos Humanos",
      url: "/about-us/rr-hh",
      subItems: [
        {
          title: "Trabaja con nosotros",
          url: "/about-us/trabaja-con-nosotros",
        },
        {
          title: "Ofertas de empleo",
          url: "/about-us/ofertas-empleo",
        },
      ],
    },
    {
      title: "Localización",
      url: "/about-us/localizacion",
    },
    {
      title: "Punto de venta",
      url: "/about-us/punto-venta",
    },
    {
      title: "Objetivos",
      url: "/about-us/objetivos",
    },
  ]

  return (
    <Layout>
      <div className="container">
        <div className="margin-y-lg">
          <div className="grid gap-xl">
            <div className="col-12">
              <h1 className="text-md">Quiénes somos</h1>
            </div>
            <div className="col-12 col-4@sm">
              <ResponsiveSidebar title="Quiénes somos">
                <SidenavNavigation
                  sidenavNavigationListItems={sidenavNavigationListItems}
                />
              </ResponsiveSidebar>
            </div>
            <div className="col-12 col-8@sm">
              <div className="margin-y-sm text-component">
                <h2 className="text-sm">{aboutUs.frontmatter.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: aboutUs.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUsTemplate
